// import PropTypes from 'prop-types';
// import { basePageWrap } from '../BasePage';
import { useTranslation } from 'next-i18next';
import Image from 'next/legacy/image';
import Link from 'next/link';
import ButtonPrimary from '../ButtonPrimary';
import HeaderMyPagesDropdown from '../HeaderMyPagesDropdown';
import styles from './HeaderDesktop.module.scss';

const HeaderDesktop = ({ isAuthenticated, user = {}, globalSettings }) => {
  const { t } = useTranslation();
  const shouldShowBookTime =
    !['advisor', 'supplier', 'supplier-admin'].includes(user?.role) &&
    user.role !== 'superuser';
  const shouldShowCreateActivity =
    ['advisor', 'supplier', 'supplier-admin'].includes(user?.role) &&
    user.role !== 'superuser';
  const shouldShowInvoice = user.role === 'superuser';

  const totalNotifications = globalSettings?.personalNotifications?.total || 0;

  return (
    <div className={`${styles.Root}`}>
      <div className={`${styles.HeaderContainer} container`}>
        <div className={styles.LogoContainer}>
          <Link href="/">
            <Image
              alt="Stockholms stad logotyp"
              src="/img/stockholms-stad.svg"
              width="125"
              height="45"
              className={styles.Logo}
            />
          </Link>
        </div>
        <Link href="/" className={styles.Name}>
          Starta eget Stockholm
        </Link>
        <nav className={styles.Navigation} id="navigation">
          <div className={styles.NavigationWrap}>
            <Link href="/vanliga-fragor/" className={styles.Link}>
              {shouldShowCreateActivity || shouldShowInvoice
                ? t('Header.links.faqAdvisor')
                : t('Header.faq')}
            </Link>
          </div>
          {!isAuthenticated && (
            <div className={styles.NavigationWrap}>
              <ButtonPrimary
                isLink={true}
                href="/wt/saml2/login/"
                mtmData={{
                  event: 'ctaButtonClick',
                  ctaText: t('Header.login'),
                  ctaFormat: 'primary-button',
                }}
              >
                {t('Header.login')}
              </ButtonPrimary>
            </div>
          )}
          {isAuthenticated &&
            shouldShowBookTime &&
            user.livesInStockholm &&
            user.acceptedTos && (
              <div className={styles.NavigationWrap}>
                <ButtonPrimary
                  isLink={true}
                  href="/boka-tid/"
                  mtmData={{
                    event: 'ctaButtonClick',
                    ctaText: t('Header.bookTime'),
                    ctaFormat: 'primary-button',
                  }}
                >
                  {t('Header.bookTime')}
                </ButtonPrimary>
              </div>
            )}
          {isAuthenticated && shouldShowCreateActivity && (
            <div className={styles.NavigationWrap}>
              <ButtonPrimary
                isLink={true}
                href="/installningar/lagg-till-aktivitet/"
                mtmData={{
                  event: 'ctaButtonClick',
                  ctaText: t('Header.createActivity'),
                  ctaFormat: 'primary-button',
                }}
              >
                {t('Header.createActivity')}
              </ButtonPrimary>
            </div>
          )}
          {isAuthenticated && shouldShowInvoice && (
            <div className={styles.NavigationWrap}>
              <ButtonPrimary
                isLink={true}
                href="/faktureringsunderlag/"
                mtmData={{
                  event: 'ctaButtonClick',
                  ctaText: t('Header.invoicePage'),
                  ctaFormat: 'primary-button',
                }}
              >
                {t('Header.invoicePage')}
              </ButtonPrimary>
            </div>
          )}
          <HeaderMyPagesDropdown
            user={user}
            totalNotifications={totalNotifications}
            notifications={globalSettings?.personalNotifications}
            isAuthenticated={isAuthenticated}
          />
        </nav>
      </div>
    </div>
  );
};

HeaderDesktop.propTypes = {};

HeaderDesktop.defaultProps = {};

// export default basePageWrap(HeaderDesktop);
export default HeaderDesktop;
