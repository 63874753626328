import * as React from 'react';

import Image from 'next/legacy/image';
import styles from './Footer.module.scss';
import dynamic from 'next/dynamic';
const CookieConsent = dynamic(() => import('../CookieConsent'));

const Footer = ({ text, links = [], cookieConsent = {} }) => {
  return (
    <div className={styles.Root}>
      <div className={`${styles.Container} container`}>
        <div className={styles.Wrapper}>
          <div className={styles.LogoContainer}>
            <div className={styles.Logo}>
              <Image
                alt=""
                src="/img/stockholms-stad.svg"
                width="125"
                height="45"
              />
            </div>
            <div className={styles.Name}>Starta eget Stockholm</div>
          </div>
          <span className={styles.Title}>{text}</span>
        </div>
        <div className={styles.Wrapper}>
          <ul className={styles.Links}>
            {links.map((link) => (
              <li key={link.link + link.linkText} className={styles.Link}>
                <a href={link.link} className={styles.Url}>
                  {link.linkText}
                </a>
              </li>
            ))}

            {!!cookieConsent?.title && (
              <li className={styles.Link}>
                <CookieConsent {...cookieConsent} />
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
