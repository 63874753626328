import { useTranslation } from 'next-i18next';
// import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import Icon from '../Icon';
import Cookies from 'js-cookie';
import styles from './NotificationResync.module.scss';
import classNames from 'classnames';

const NotificationResync = ({ relinkUrl, identifier }) => {
  const { t } = useTranslation();
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    if (Cookies.get(identifier) === '1') {
      setHidden(true);
    } else {
      setHidden(false);
    }
  }, []);

  return (
    <div
      className={classNames(styles.Root, {
        [styles.RootHidden]: hidden,
      })}
    >
      <div className={styles.Text}>
        {t('NotificationResync.text')}{' '}
        <a href={relinkUrl}>Klicka här för att synka din kalender.</a>
      </div>
      <button
        aria-label="Stäng"
        type="button"
        onClick={() => {
          Cookies.set(identifier, 1, { expires: 7 });
          setHidden(true);
        }}
        className={styles.Close}
      >
        <Icon src="/img/icon-x-white.svg" width={20} height={20} />
      </button>
    </div>
  );
};

NotificationResync.propTypes = {};

NotificationResync.defaultProps = {};

export default NotificationResync;
