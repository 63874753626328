// import { useTranslation } from 'next-i18next'
// import PropTypes from 'prop-types';
// import { basePageWrap } from '../BasePage';
import React from 'react';
import Link from 'next/link';
import styles from './ButtonPrimary.module.scss';
import classNames from 'classnames';
import * as mtm from '../../utils/mtm';

const ButtonPrimary = ({
  isLink = false,
  children,
  withButtonsStyle,
  modifier,
  mtmData,
  ...props
}) => {
  const classes = classNames(styles.Container, {
    [styles.Link]: isLink,
    [styles.Button]: !isLink,
    [styles.WithButtonsStyle]: withButtonsStyle,
    [styles.Disabled]: modifier === 'disabled',
    [styles.CheckboxListExpandButton]: modifier === 'checkbox-list-button',
  });

  const extraProps = {};
  if (!!mtmData) {
    extraProps.onClick = () => {
      mtm.mtmPush(mtmData);
      if (props.onClick) {
        props.onClick();
      }
    };
  }

  return isLink ? (
    <Link {...props} className={styles.Root + ' ' + classes}>
      {children}
    </Link>
  ) : (
    <button
      disabled={modifier === 'disabled'}
      className={styles.Root + ' ' + classes}
      {...props}
      {...extraProps}
    >
      {children}
    </button>
  );
};

ButtonPrimary.propTypes = {};

ButtonPrimary.defaultProps = {};

// export default basePageWrap(ButtonPrimary);
export default ButtonPrimary;
