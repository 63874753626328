// import { useTranslation } from 'next-i18next'
// import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useState } from 'react';
import Icon from '../Icon';
import styles from './Notification.module.scss';
import Cookies from 'js-cookie';

const Notification = ({ text, type, identifier }) => {
  const [hidden, setHidden] = useState(false);

  return (
    <div
      className={classNames(styles.Root, {
        [styles.RootWarning]: type === 'warning',
        [styles.RootInfo]: type === 'info',
        [styles.RootHidden]: hidden,
      })}
    >
      <div className={styles.Text} dangerouslySetInnerHTML={{ __html: text }} />
      <button
        aria-label="Stäng"
        type="button"
        onClick={() => {
          Cookies.set(identifier, 1, { expires: 7 });
          setHidden(true);
        }}
        className={styles.Close}
      >
        <Icon src="/img/icon-x.svg" width={20} height={20} />
      </button>
    </div>
  );
};

Notification.propTypes = {};

Notification.defaultProps = {};

export default Notification;
