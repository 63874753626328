import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import ServiceMenu from '../../components/ServiceMenu';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import styles from './BasePage.module.scss';

const queryClient = new QueryClient();

const BasePage = ({
  children,
  title,
  isAuthenticated,
  user,
  globalSettings = {},
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Head>
        <title>{title || 'Starta eget'}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className={styles.Root}>
        <ServiceMenu isAuthenticated={isAuthenticated} user={user} />
        <Header
          isAuthenticated={isAuthenticated}
          user={user}
          globalSettings={globalSettings}
          notification={globalSettings.notification}
          advisorRelinkNotification={globalSettings.advisorRelinkNotification}
        />
        <main className={styles.Body}>{children}</main>
        <Footer
          {...globalSettings.footer}
          cookieConsent={globalSettings.cookieConsent}
        />
      </div>
    </QueryClientProvider>
  );
};

BasePage.defaultProps = {};

BasePage.propTypes = {
  children: PropTypes.node,
};

export default BasePage;
