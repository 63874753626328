import React from 'react';

// import PropTypes from 'prop-types';
import styles from './Header.module.scss';
import HeaderDesktop from '../HeaderDesktop';
import Notification from '../Notification';
import NotificationResync from '../NotificationResync';
import HeaderMobile from '../HeaderMobile';

const Header = ({
  isAuthenticated,
  user = {},
  notification,
  advisorRelinkNotification,
  globalSettings = {},
}) => {
  return (
    <>
      <div className={styles.HeaderDesktopContainer}>
        <HeaderDesktop
          globalSettings={globalSettings}
          isAuthenticated={isAuthenticated}
          user={user}
        />
        {!!notification && <Notification {...notification} />}
        {!!advisorRelinkNotification && (
          <NotificationResync {...advisorRelinkNotification} />
        )}
      </div>
      <div className={styles.HeaderMobileContainer}>
        <HeaderMobile
          isAuthenticated={isAuthenticated}
          user={user}
          notification={notification}
        />
      </div>
    </>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
