import { useTranslation } from 'next-i18next';
// import PropTypes from 'prop-types';
// import { basePageWrap } from '../BasePage';
import {
  Select,
  SelectItem,
  SelectLabel,
  SelectPopover,
  useSelectState,
} from 'ariakit/select';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import getHeaderLinks from '../../utils/get-header-links';
import styles from './HeaderMyPagesDropdown.module.scss';
import Caret from '../../public/img/filter-arrow-down.svg';

const HeaderMyPagesDropdown = ({
  user,
  isAuthenticated,
  totalNotifications = 0,
  notifications,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const links = getHeaderLinks(user, isAuthenticated, t, notifications, true);

  const select = useSelectState({
    defaultValue: !!links && !!links.length ? links[0].link : null,
    sameWidth: true,
  });

  const inputClasses = classNames(styles.Input, {
    [styles['Input--IsOpen']]: select.open,
  });
  const caretClasses = classNames(styles.Caret, {
    [styles['Caret--Up']]: select.open,
  });

  return (
    <div className={styles.Root}>
      {totalNotifications > 0 && (
        <div className={styles.Notifications}>
          <span className={styles.Num}>{totalNotifications}</span>
        </div>
      )}
      <div>
        <div className={styles.LabelContainer}>
          <SelectLabel className={'sr-only'} state={select}>
            {isAuthenticated
              ? t('HeaderMyPagesDropdown.label')
              : t('HeaderMyPagesDropdown.labelUnauthenticated')}
          </SelectLabel>
        </div>
        <Select state={select} className={inputClasses}>
          <span /> {/* used to align label to the center */}
          {isAuthenticated
            ? t('HeaderMyPagesDropdown.label')
            : t('HeaderMyPagesDropdown.labelUnauthenticated')}
          <Caret aria-hidden={true} className={caretClasses} />
        </Select>
        {select.mounted && (
          <SelectPopover state={select} className={styles.ItemsWrapper}>
            {links
              .filter((i) => !i.isMobileOnly)
              .map(({ link, label, notifications }) => {
                return (
                  <SelectItem
                    key={link}
                    value={link}
                    className={styles.Item}
                    onClick={() => {
                      router.push(link);
                    }}
                  >
                    <span className={styles.ItemText}>
                      {label}
                      {!!notifications && notifications > 0 && (
                        <div className={styles.ItemNotifications}>
                          <span className={styles.ItemNum}>
                            {notifications}
                          </span>
                        </div>
                      )}
                    </span>
                  </SelectItem>
                );
              })}
          </SelectPopover>
        )}
      </div>
    </div>
  );
};

HeaderMyPagesDropdown.propTypes = {};

HeaderMyPagesDropdown.defaultProps = {};

// export default basePageWrap(HeaderMyPagesDropdown);
export default HeaderMyPagesDropdown;
