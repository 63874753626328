import React, { useState } from 'react';

import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import Link from 'next/link';
import Icon from '../Icon';
// import PropTypes from 'prop-types';
import styles from './ServiceMenu.module.scss';

const ServiceMenu = ({ isAuthenticated, user = {} }) => {
  const { t } = useTranslation();
  const router = useRouter() || {};
  const { asPath: currentUrl, locale } = router;
  const newLocale = locale === 'sv' ? 'en' : 'sv';

  let userType = '';
  if (user.role === 'superuser') {
    userType = t('ServiceMenu.admin');
  } else if (user.isAdvisor && user.role !== 'supplier-admin') {
    userType = t('ServiceMenu.advisor');
  } else if (user.role === 'supplier-admin') {
    userType = t('ServiceMenu.supplier');
  }

  if (!userType && isAuthenticated) {
    return null;
  }

  return (
    <div className={styles.Root}>
      <div className={`${styles.Container} container`}>
        {isAuthenticated && (
          <>
            <Icon src={'/img/icon-person-black.svg'} height={16} width={16} />
            <span className={styles.LanguageLabel}>
              Inloggad som {userType}.
            </span>
            <Link href={'/logga-ut/'} className={styles.LanguageLabel}>
              {t(`ServiceMenu.logout`)}
            </Link>
          </>
        )}
        {!isAuthenticated && (
          <>
            <Icon src={'/img/icon-lock.svg'} height={16} width={16} />
            <Link href={'/logga-in/'} className={styles.LanguageLabel}>
              {t(`ServiceMenu.login`)}
            </Link>
          </>
        )}
        {/*
            <Icon src={'/img/globe.svg'} height={16} width={16} />
            {currentUrl && (
                <Link href={currentUrl} locale={newLocale}>
                    <a className={styles.LanguageLabel} lang={newLocale}>
                        {t(`ServiceMenu.${newLocale}`)}
                    </a>
                </Link>
            )}
              */}
      </div>
    </div>
  );
};

ServiceMenu.propTypes = {};

ServiceMenu.defaultProps = {};

export default ServiceMenu;
