// import { useTranslation } from 'next-i18next'
import React from 'react';
import PropTypes from 'prop-types';
// import { basePageWrap } from '../BasePage';
import styles from './Icon.module.scss';
import Image from 'next/legacy/image';
import classNames from 'classnames';

const Icon = ({
  src,
  height,
  width,
  modifier,
  imageClass = '',
  extraClass,
}) => {
  const classes = classNames(
    styles.Root,
    {
      [styles.UpsideDown]: modifier === 'upsidedown',
      [styles.Normal]: modifier === 'normal',
      [styles.PositionRight]: modifier === 'right',
    },
    extraClass
  );

  return (
    <span className={classes}>
      {src && (
        <Image
          src={src}
          height={height}
          width={width}
          alt=""
          className={imageClass}
        />
      )}
    </span>
  );
};

Icon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

Icon.defaultProps = {};

// export default basePageWrap(Icon);
export default Icon;
