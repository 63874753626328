import { useTranslation } from 'next-i18next';
// import PropTypes from 'prop-types';
// import { basePageWrap } from '../BasePage';

import classNames from 'classnames';
import Image from 'next/legacy/image';
import Icon from '../Icon/Icon';
import Notification from '../Notification';
import getHeaderLinks from '../../utils/get-header-links';
import useScrollDirection from '../../utils/use-scroll-direction';
import styles from './HeaderMobile.module.scss';
import {
  Dialog,
  DialogDismiss,
  DialogHeading,
  useDialogState,
} from 'ariakit/dialog';
import Link from 'next/link';

const HeaderMobile = ({ user, isAuthenticated, notification }) => {
  const scrollDirection = useScrollDirection(50);
  const { t } = useTranslation();
  const dialog = useDialogState({ animated: true });
  const links = getHeaderLinks(user, isAuthenticated, t);

  const rootClasses = classNames(styles.Root, {
    [styles.RootUp]: scrollDirection === 'up',
    [styles.RootDown]: scrollDirection === 'down',
  });
  const firstLineClasses = classNames(styles.Line, {
    [styles.LineOneOpen]: dialog.open,
  });
  const secondLineClasses = classNames(styles.Line, {
    [styles.LineTwoOpen]: dialog.open,
  });

  const buttonClasses = classNames(styles.Button, {
    [styles.ButtonOpen]: dialog.open,
  });

  return (
    <div className={rootClasses}>
      <div className={styles.LogoContainer}>
        <Link href="/">
          <Image
            alt="Stockholms stad logotyp"
            src="/img/stockholms-stad.svg"
            width="80"
            height="29"
            className={styles.Logo}
          />
        </Link>
      </div>
      <div className={styles.Name}>Starta eget Stockholm</div>
      <button type="button" className={buttonClasses} onClick={dialog.toggle}>
        <span className="sr-only">Open menu</span>
        <span className={firstLineClasses} />
        <span className={secondLineClasses} />
      </button>
      <Dialog state={dialog} className={styles.Dialog}>
        <button type="button" className={buttonClasses} onClick={dialog.toggle}>
          <span className="sr-only">Open menu</span>
          <span className={firstLineClasses} />
          <span className={secondLineClasses} />
        </button>
        <div className={styles.LinksContainer}>
          {user && user.role === 'advisor' && (
            <div className={styles.TopLinksWrapper}>
              <span className={styles.TopLink}>
                {' '}
                <Icon
                  className={styles.TopLinkIcon}
                  color="white"
                  src={'/img/globe-white.svg'}
                  height={16}
                  width={16}
                />
                <button className={styles.TopLinkButton}>In english</button>
              </span>
              <span className={styles.TopLink}>
                <Icon
                  className={styles.TopLinkIcon}
                  src={'/img/icon-lock-white.svg'}
                  color={'white'}
                  height={18}
                  width={16}
                />
                {t('Header.links.loggedInAsAdvisor')}
              </span>
            </div>
          )}
          {user && user.role === 'user' && (
            <>
              {' '}
              <div className={styles.TopLinkWrapper}>
                <span className={styles.TopLink}>
                  <Icon
                    className={styles.TopLinkIcon}
                    src={'/img/icon-lock-white.svg'}
                    height={18}
                    width={16}
                  />
                  <button className={styles.TopLinkButton}>
                    {t('Header.links.login')}
                  </button>
                </span>
              </div>
            </>
          )}
          {links.map((link, i) => (
            <Link key={i} href={link.link} className={styles.Link}>
              {link.label}
            </Link>
          ))}
        </div>
      </Dialog>
      {!!notification && <Notification {...notification} />}
    </div>
  );
};

HeaderMobile.propTypes = {};

HeaderMobile.defaultProps = {};

// export default basePageWrap(HeaderMobile);
export default HeaderMobile;
