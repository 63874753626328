const getHeaderLinks = (
  user = {},
  isAuthenticated,
  t,
  notifications = {},
  isDesktop
) => {
  const showAdmin = user.role === 'superuser';
  const showSupplier =
    user.role === 'supplier' || user.role === 'supplier-admin' || showAdmin;
  const showAdvisor = user.role === 'advisor' || showSupplier;
  const isSupplierAdmin = user.role === 'supplier-admin';
  const showUser = user.role === 'customer';
  const livesInStockholm = user.livesInStockholm;
  const hasAcceptedTos = user.acceptedSecrecy && user.acceptedTos;

  const administratorMenu = [
    {
      link: '/installningar/lagg-till-leverantor/',
      label: t('Header.links.addSupplier'),
    },
    {
      link: '/installningar/lagg-till-radgivare/',
      label: t('Header.links.addAdvisor'),
    },
    {
      link: '/faktureringsunderlag/',
      label: t('Header.links.invoice'),
    },
    {
      link: '/statistik/',
      label: t('Header.links.statistics'),
    },
    {
      link: '/wt/cms/',
      label: 'Wagtail',
    },
  ];

  const advisorMenu = [
    {
      link: `/installningar/lagg-till-aktivitet/`,
      label: t('Header.links.addActivity'),
    },
    {
      link: `/mina-aktiviteter/`,
      label: t('Header.links.myActivities'),
      notifications: notifications?.numBookingsToBeReviewed || 0,
    },
    {
      link: `/boka-tid/`,
      label: t('Header.links.bookTimeAdvisor'),
    },
    {
      link: `/mina-recensioner/`,
      label: t('Header.links.myReviews'),
    },
    {
      link: `/radgivare/${user.id}/`,
      label: t('Header.links.advisorProfile'),
    },
    // { link: '/installningar/', label: t('Header.links.advisorSettings') },
  ];

  const supplierMenu = [
    {
      link: `/installningar/lagg-till-aktivitet/`,
      label: t('Header.links.addActivity'),
    },
    isSupplierAdmin && !!user.supplier
      ? {
          link: '/faktureringsunderlag/',
          label: t('Header.links.invoice'),
        }
      : null,
    {
      link: `/mina-aktiviteter/`,
      label: t('Header.links.myActivities'),
      notifications: notifications?.numBookingsToBeReviewed || 0,
    },
    {
      link: `/boka-tid/`,
      label: t('Header.links.bookTimeAdvisor'),
    },
    {
      link: `/mina-recensioner/`,
      label: t('Header.links.myReviews'),
    },
    {
      link: `/radgivare/${user.id}/`,
      label: t('Header.links.advisorProfile'),
    },
    {
      link: `/leverantor/${user.supplier?.id || user.supplier}/`,
      label: t('Header.links.supplierProfile'),
    },
  ].filter((i) => !!i);

  const userMenu = [
    isAuthenticated &&
      user?.role === 'customer' && {
        link: `/installningar/`,
        label: t('Header.links.settings'),
        notifications: notifications?.numSettingsNotFilledIn || 0,
      },
    {
      link: `/boka-tid/`,
      label: t('Header.links.bookTime'),
    },
    {
      link: `/leverantor/`,
      label: t('Header.links.showSuppliers'),
    },
    {
      link: `/radgivare/`,
      label: t('Header.links.showAdvisors'),
    },
    {
      link: `/mina-aktiviteter/`,
      label: t('Header.links.myActivities'),
      notifications: notifications?.numBookingsToBeReviewed || 0,
    },
  ].filter((i) => !!i);

  const otherLinks = [
    {
      isMobileOnly: true,
      link: `/vanliga-fragor/`,
      label:
        showAdvisor || showAdmin
          ? t('Header.links.faqAdvisor')
          : t('Header.links.faq'),
    },
    !isAuthenticated && {
      isMobileOnly: true,
      link: `/wt/saml2/login/`,
      label: t('Header.links.loginUser'),
    },
    isAuthenticated
      ? {
          link: `/logga-ut/`,
          label: t('Header.links.logout'),
        }
      : {
          isMobileOnly: true,
          link: `/logga-in/`,
          label: t('Header.links.login'),
        },
    !isAuthenticated && {
      link: `/boka-tid/`,
      label: t('Header.links.bookTime'),
    },
    !isAuthenticated && {
      link: `/leverantor/`,
      label: t('Header.links.showSuppliers'),
    },
    !isAuthenticated && {
      link: `/radgivare/`,
      label: t('Header.links.showAdvisors'),
    },
  ];

  let allLinks = [];

  if (showAdmin) {
    allLinks = [...administratorMenu, ...otherLinks];
  } else if (showSupplier) {
    allLinks = [...supplierMenu, ...otherLinks];
  } else if (showAdvisor) {
    allLinks = [...advisorMenu, ...otherLinks];
  } else if (showUser && livesInStockholm) {
    allLinks = [...userMenu, ...otherLinks];
  } else if (!livesInStockholm) {
    allLinks = [...otherLinks];
  }
  if (user.role === 'customer' && !hasAcceptedTos) {
    allLinks = [...otherLinks];
  }

  allLinks = allLinks.filter((x) => !!x);

  return allLinks;
};

export default getHeaderLinks;
